const TutorialIcon = ({ color }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipPath="evenodd"
        d="M7.18031 1.25C4.63484 1.25 2.57031 3.32705 2.57031 5.86V19.95C2.57031 21.0157 2.95864 21.9575 3.78221 22.4425C4.60519 22.9272 5.61779 22.8114 6.55207 22.297L6.55443 22.2957L11.4356 19.585C11.5574 19.5171 11.7638 19.4625 12.0091 19.4625C12.255 19.4625 12.456 19.5174 12.5696 19.582L17.4562 22.2957C18.3895 22.8153 19.4034 22.9359 20.2284 22.45C21.0533 21.9642 21.4403 21.0187 21.4403 19.95L21.4403 5.86L21.4403 5.85646C21.4284 3.32697 19.3665 1.25 16.8203 1.25H7.18031ZM4.07031 5.86C4.07031 4.15295 5.46579 2.75 7.18031 2.75H16.8203C18.5336 2.75 19.9314 4.15219 19.9403 5.862V19.95C19.9403 20.6913 19.6823 21.0308 19.4672 21.1575C19.2525 21.284 18.8309 21.3444 18.1844 20.9843L14.3297 18.8437L13.3074 18.276C12.9117 18.0521 12.4393 17.9625 12.0091 17.9625C11.577 17.9625 11.1032 18.0531 10.705 18.275L5.82855 20.983L5.82737 20.9837C5.18232 21.3385 4.7603 21.2777 4.54342 21.15C4.32698 21.0225 4.07031 20.6843 4.07031 19.95V7.51617V5.86ZM15.6206 9.03033C15.9135 8.73744 15.9135 8.26256 15.6206 7.96967C15.3277 7.67678 14.8529 7.67678 14.56 7.96967L11.0903 11.4393L10.1206 10.4697C9.82774 10.1768 9.35286 10.1768 9.05997 10.4697C8.76708 10.7626 8.76708 11.2374 9.05997 11.5303L10.56 13.0303C10.8529 13.3232 11.3277 13.3232 11.6206 13.0303L15.6206 9.03033Z"
        fill={color}
      />
    </svg>
  )
}

export default TutorialIcon
