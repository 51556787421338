import { Box, HStack, IconButton, Image, Stack, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import BerandaIcon from './BerandaIcon'
import BimbinganIcon from './BimbinganIcon'
import TutorialIcon from './TutorialIcon'
import ProfileIcon from './ProfileIcon'
import Overlay from '../misc/Overlay'
import { useRouter } from 'next/router'
import { useDispatch } from 'react-redux'
import BottomSheetMealTime from '../bottom-sheets/BottomSheetMealTime'

const BottomNavigationBar = () => {
  const dispatch = useDispatch()
  const [active, setActive] = useState('')
  const [open, setOpen] = useState(false)
  const [openMealTime, setOpenMealTime] = useState(false)

  const router = useRouter()
  useEffect(() => {
    if (router.pathname === '/') {
      setActive('beranda')
    } else if (router.pathname === '/mentorship') {
      setActive('bimbingan')
    } else if (router.pathname === '/tutorial') {
      setActive('tutorial')
    } else if (router.pathname === '/account') {
      setActive('profil')
    }
  }, [router])

  const getActiveColor = (menu) => {
    return active === menu ? '#FF2C4B' : '#D9DBE9'
  }
  return (
    <>
      <Box mt="120px" />
      <Box
        position="fixed"
        bottom="-10px"
        left={{
          base: '0',
          sm: 'calc(50% - 240px)',
        }}
        right="0"
        zIndex={999}
      >
        {open && <Overlay />}
        <HStack
          maxW="480px"
          bg="white"
          border="1px solid #D9DBE9"
          borderRadius="12px"
          pt="16px"
          pb="24px"
          px="16px"
          position="relative"
          justifyContent="space-between"
        >
          <Stack spacing={2} alignItems="center" cursor="pointer" w="calc(100%/5)" onClick={() => router.push('/')}>
            <BerandaIcon color={getActiveColor('beranda')} />
            <Text fontWeight={600} color={getActiveColor('beranda')}>
              Beranda
            </Text>
          </Stack>
          <Stack
            spacing={2}
            alignItems="center"
            cursor="pointer"
            w="calc(100%/5)"
            onClick={() => router.push('/mentorship')}
          >
            <BimbinganIcon color={getActiveColor('bimbingan')} />
            <Text color={getActiveColor('bimbingan')} fontWeight={600}>
              Chat
            </Text>
          </Stack>
          <Stack w="calc(100%/5)" />
          <Stack
            spacing={2}
            alignItems="center"
            cursor="pointer"
            w="calc(100%/5)"
            onClick={() => router.push('/tutorial')}
          >
            <TutorialIcon color={getActiveColor('tutorial')} />
            <Text color={getActiveColor('tutorial')} fontWeight={600}>
              Tutorial
            </Text>
          </Stack>
          <Stack
            spacing={2}
            alignItems="center"
            cursor="pointer"
            w="calc(100%/5)"
            onClick={() => router.push('/account')}
          >
            <ProfileIcon color={getActiveColor('profil')} />
            <Text color={getActiveColor('profil')} fontWeight={600}>
              Profil
            </Text>
          </Stack>
          <IconButton
            cursor="pointer"
            bg="logo.primary"
            position="absolute"
            left="49%"
            top="0"
            transform="translate(-50%, -50%)"
            borderRadius="50%"
            w="60px"
            h="60px"
            border="6px solid #FFD5DB"
            _hover={{
              bg: 'logo.primary',
            }}
            onClick={() => {
              setOpen(!open)
              dispatch.mealplan.setMealTime('')
            }}
            zIndex={9999}
          >
            <Image
              alt=""
              w="18px"
              src="/icons/plus.svg"
              style={{
                rotate: open ? '45deg' : '0deg',
                transition: 'all 0.3s ease-in-out',
              }}
            />
          </IconButton>

          <IconButton
            cursor="pointer"
            bg="white"
            position="absolute"
            transform="translate(-50%, -50%)"
            borderRadius="50%"
            w="60px"
            h="60px"
            _hover={{
              bg: 'white',
            }}
            boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
            left={open ? 'calc(49% - 60px - 12px)' : '49%'}
            top={open ? '-48px' : '0px'}
            opacity={open ? 1 : 0}
            transition="all 0.3s ease-in-out"
            zIndex={999}
            onClick={() => setOpenMealTime(true)}
          >
            <Image src="/icons/book.svg" alt="" w="28px" />
          </IconButton>

          <IconButton
            cursor="pointer"
            bg="white"
            position="absolute"
            transform="translate(-50%, -50%)"
            borderRadius="50%"
            w="60px"
            h="60px"
            _hover={{
              bg: 'white',
            }}
            boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
            left="49%"
            top={open ? '-96px' : '0px'}
            opacity={open ? 1 : 0}
            transition="all 0.3s ease-in-out"
            zIndex={999}
            onClick={() => router.push('/workouts')}
          >
            <Image src="/icons/weight.svg" alt="" w="28px" />
          </IconButton>

          <IconButton
            cursor="pointer"
            bg="white"
            position="absolute"
            transform="translate(-50%, -50%)"
            borderRadius="50%"
            w="60px"
            h="60px"
            _hover={{
              bg: 'white',
            }}
            boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
            left={open ? 'calc(49% + 60px + 12px)' : '49%'}
            top={open ? '-48px' : '0px'}
            opacity={open ? 1 : 0}
            transition="all 0.3s ease-in-out"
            zIndex={999}
            onClick={() => router.push('/measurements')}
          >
            <Image src="/icons/ruler.svg" alt="" w="28px" />
          </IconButton>
        </HStack>
      </Box>
      <BottomSheetMealTime open={openMealTime} setOpen={setOpenMealTime} />
    </>
  )
}

export default BottomNavigationBar
