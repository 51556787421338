const BerandaIcon = ({ color }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.0703 2.81984L3.14027 8.36983C2.36027 8.98983 1.86027 10.2999 2.03027 11.2799L3.36027 19.2398C3.60027 20.6598 4.96026 21.8098 6.40026 21.8098H17.6003C19.0303 21.8098 20.4003 20.6498 20.6403 19.2398L21.9703 11.2799C22.1303 10.2999 21.6303 8.98983 20.8603 8.36983L13.9303 2.82985C12.8603 1.96985 11.1303 1.96984 10.0703 2.81984Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.4"
        d="M12 15.5C13.3807 15.5 14.5 14.3807 14.5 13C14.5 11.6193 13.3807 10.5 12 10.5C10.6193 10.5 9.5 11.6193 9.5 13C9.5 14.3807 10.6193 15.5 12 15.5Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default BerandaIcon
