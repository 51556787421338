import { HStack, Image, Radio, Stack, Text } from '@chakra-ui/react'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BottomSheet } from 'react-spring-bottom-sheet'

const RadioButtonMealTime = ({ mealtime, checked, onChange, noRedirect, setOpen, handleChange }) => {
  const router = useRouter()
  const dispatch = useDispatch()

  const handleClick = () => {
    onChange()
    if (!handleChange) {
      dispatch.mealplan.setMealTime(mealtime)
    } else {
      handleChange(mealtime)
    }
    if (!noRedirect) {
      dispatch.mealplan.getInitialFoodKeywordSuggestion({ name: '' })
      router.push('/meal-plan')
    } else {
      setOpen(false)
    }
  }

  return (
    <HStack
      alignItems="center"
      justifyContent="space-between"
      mb="8px"
      border="1px solid #D9DBE9"
      borderRadius="8px"
      p="11px 16px"
      cursor="pointer"
      onClick={handleClick}
    >
      <Text fontSize={14} fontWeight={500}>
        {mealtime}
      </Text>
      <Radio value={mealtime} isChecked={checked} onChange={onChange} />
    </HStack>
  )
}

// handleChange & value props to handle local change instead of global mealplan state change (used in edit food)
const BottomSheetMealTime = ({ open, setOpen, noRedirect, handleChange, value }) => {
  const mealtimes = ['Sarapan', 'Makan Siang', 'Makan Malam', 'Cemilan']
  const { mealTime: stateMealTime } = useSelector((state) => state.mealplan)

  const [checked, setChecked] = useState('')
  const [mealTime, setMealTime] = useState('')

  useEffect(() => {
    setMealTime(stateMealTime)
    setChecked(value ? value : stateMealTime)
  }, [stateMealTime])

  return (
    <BottomSheet open={open} onDismiss={() => setOpen(false)} title="Pilih Waktu Makan">
      <HStack mx="16px" pb="16px" justify="space-between" borderBottom="1px solid" borderColor="default.line">
        <Text fontSize={16} color="default.title" fontWeight="semibold">
          Pilih Waktu Makan
        </Text>
        <Image src="/icons/close.svg" alt="close" cursor="pointer" onClick={() => setOpen(false)} />
      </HStack>

      <Stack mx="16px" mt="16px" mb="24px">
        {mealtimes.map((mealtime) => (
          <RadioButtonMealTime
            key={mealtime}
            mealtime={mealtime}
            checked={checked === mealtime}
            onChange={() => setChecked(mealtime)}
            noRedirect={noRedirect}
            setOpen={setOpen}
            handleChange={handleChange}
          />
        ))}
      </Stack>
    </BottomSheet>
  )
}

export default BottomSheetMealTime
