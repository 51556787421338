const BimbinganIcon = ({ color }) => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.98 11.2744V15.2744C17.98 15.5344 17.97 15.7844 17.94 16.0244C17.71 18.7244 16.12 20.0644 13.19 20.0644H12.79C12.54 20.0644 12.3 20.1844 12.15 20.3844L10.95 21.9844C10.42 22.6944 9.56 22.6944 9.03 21.9844L7.82999 20.3844C7.69999 20.2144 7.41 20.0644 7.19 20.0644H6.79001C3.60001 20.0644 2 19.2744 2 15.2744V11.2744C2 8.34438 3.35001 6.75438 6.04001 6.52438C6.28001 6.49438 6.53001 6.48438 6.79001 6.48438H13.19C16.38 6.48438 17.98 8.08438 17.98 11.2744Z"
        stroke={color}
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        opacity="0.4"
        d="M21.98 7.27438V11.2744C21.98 14.2144 20.63 15.7944 17.94 16.0244C17.97 15.7844 17.98 15.5344 17.98 15.2744V11.2744C17.98 8.08438 16.38 6.48438 13.19 6.48438H6.79004C6.53004 6.48438 6.28004 6.49438 6.04004 6.52438C6.27004 3.83438 7.86004 2.48438 10.79 2.48438H17.19C20.38 2.48438 21.98 4.08438 21.98 7.27438Z"
        stroke={color}
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        opacity="0.4"
        d="M13.4955 13.7344H13.5045"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        opacity="0.4"
        d="M9.9955 13.7344H10.0045"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        opacity="0.4"
        d="M6.4955 13.7344H6.5045"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <circle cx="19" cy="5.48438" r="4" fill={color} stroke="white" stroke-width="2" />
    </svg>
  )
}

export default BimbinganIcon
