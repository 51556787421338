import { Box } from '@chakra-ui/react'
import BottomNavigationBar from '@/components/common/bottom-navigation-bar'

const Container = ({ children, mt, bgColor, px = '4', minH = '100vh', noMenu, ...props }) => {
  return (
    <>
      <Box maxW="480px" mx="auto" px={px} minH={minH} position={'relative'} bgColor={bgColor} mt={mt} {...props}>
        {children}
        {!noMenu && <BottomNavigationBar />}
      </Box>
    </>
  )
}

export default Container
