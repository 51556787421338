import { Box } from '@chakra-ui/react'
import { useEffect, useState } from 'react'

const Overlay = () => {
  const [open, setOpen] = useState(false)
  useEffect(() => {
    setOpen(true)
    return () => {
      setOpen(false)
    }
  }, [])

  return (
    <Box
      position="fixed"
      top="0"
      left="0"
      right="0"
      bottom="0"
      zIndex="2"
      transition="all 0.3s ease-in-out"
      bg={open ? 'rgba(0,0,0,0.5)' : 'rgba(0,0,0,0)'}
    />
  )
}

export default Overlay
